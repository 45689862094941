import Cookie from "@Shared/Cookie";

export function SignInViewModel() {
    var self = this;

    self.cookie = new Cookie();
    self.capLockOn = new CapsLock();

    self.setupCapLockCheck = function () {
        var capLockMessage = $('#capLockOnMessage');
        // check the state of the caps lock key
        if (self.capLockOn.isOn()) {
            capLockMessage.show();
        }

        // display a warning when caps lock is turned on
        self.capLockOn.addListener(
            function (status) {
                if (status) {
                    capLockMessage.fadeIn();
                } else {
                    capLockMessage.hide();
                };
            }
        );

    }

    self.deleteSurvicateLocalStorage = function () {
        var propertyNames = Object.keys(window.localStorage).filter((propertyName) => {
            return propertyName.indexOf("survi") === 0;
        });

        $.each(propertyNames, (i, propertyName) => {
            window.localStorage.removeItem(propertyName);
        });
    }

    self.init = function (successfulUpdate, focusField, ariaRole) {
        $('.input-validation-error').parents('.form-group').addClass('has-error');
        $('.field-validation-error').addClass('text-danger');

        if (successfulUpdate) {
            $('#passwordUpdateSuccess').show();

            setTimeout(function () {
                $('#passwordUpdateSuccess').fadeOut();
            }, 3000);
        }

        self.setupCapLockCheck();

        if (ariaRole) {
            $(".validation-summary-errors").attr("role", "alert");
        }

        focusField = focusField || 'Username';

        $('#' + focusField).trigger("focus");

        self.cookie.deleteCookie('Toaster', '/');

        self.deleteSurvicateLocalStorage();


    };
}

export function CapsLock() {

    var capsLock = false;
    var listeners = [];
    var isMac = /Mac/.test(navigator.platform);

    function isOn() {
        return capsLock;
    }

    function addListener(listener) {
        listeners.push(listener);
    }

    function handleKeyPress(e) {
        if (!e) e = window.event;

        var priorCapsLock = capsLock;
        var charCode = (e.charCode ? e.charCode : e.keyCode);

        if (charCode >= 97 && charCode <= 122) {
            capsLock = e.shiftKey;
        } else if (charCode >= 65 && charCode <= 90 && !(e.shiftKey && isMac)) {
            capsLock = !e.shiftKey;
        }

        if (capsLock !== priorCapsLock) {
            for (var index = 0; index < listeners.length; index++) {
                listeners[index](capsLock);
            }
        }

    }

    if (window.addEventListener) {
        window.addEventListener('keypress', handleKeyPress, false);
    } else {
        document.documentElement.attachEvent('onkeypress', handleKeyPress);
    }

    return {
        isOn: isOn,
        addListener: addListener
    };
};

window.SignInViewModel = SignInViewModel;
window.CapsLock = CapsLock;
